import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './PrivacyPolicy.css'; // Import the new CSS file

const PrivacyPolicy = () => {
    const lastUpdated = "July 5th, 2024";

    return (
        <React.Fragment>
            <Header />
            <div className="privacy-policy-container">
                <div className="privacy-policy-content">
                    <h2 className="privacy-policy-title">Privacy Policy</h2>
                    <p className="privacy-policy-date">Last Updated: {lastUpdated}</p>
                    <p className="privacy-policy-text">
                        At Jackpot Concierge, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our services. Please take a moment to read through this policy to understand your rights and how we safeguard your information.
                    </p>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">Information We Collect</h3>
                        <p>We collect various types of information, including:</p>
                        <ul>
                            <li>Personal identification information (Name, email address, phone number, etc.)</li>
                            <li>Payment information (Credit card details, billing address, etc.)</li>
                            <li>Usage data (How you use our service)</li>
                        </ul>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">How We Use Information</h3>
                        <p>We use the information we collect to:</p>
                        <ul>
                            <li>Provide and maintain our services</li>
                            <li>Communicate with you</li>
                            <li>Process transactions</li>
                            <li>Improve our services</li>
                            <li>Send marketing and promotional materials</li>
                        </ul>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">Data Security</h3>
                        <p>We implement a variety of security measures to maintain the safety of your personal information. Your data is stored in secure environments and is only accessible by authorized personnel.</p>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">Third-Party Services</h3>
                        <p>We may employ third-party companies and individuals to facilitate our service, provide the service on our behalf, perform service-related services, or assist us in analyzing how our service is used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">User Rights and Choices</h3>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access your personal data</li>
                            <li>Request correction of your personal data</li>
                            <li>Request deletion of your personal data</li>
                            <li>Object to processing of your personal data</li>
                            <li>Request restriction of processing your personal data</li>
                            <li>Request transfer of your personal data</li>
                        </ul>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">Changes to Privacy Policy</h3>
                        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "last updated" date at the top of this Privacy Policy.</p>
                    </section>
      
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">International Data Transfers</h3>
                        <p>Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">California Consumer Privacy Act (CCPA) and California Privacy Rights Act (CPRA) Compliance</h3>
                        <p>We respect your privacy and offer you control over your personal information. The following rights are specifically granted to residents of California, in compliance with the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA):</p>
                        <ul>
                            <li><strong>Right to Know:</strong> California residents have the right to request disclosure of the specific pieces of personal information we have collected about them.</li>
                            <li><strong>Right to Delete:</strong> California residents can request the deletion of their personal information that we have collected, subject to certain exceptions.</li>
                            <li><strong>Right to Opt-Out:</strong> California residents have the right to opt-out of the sale of their personal information. This right can be exercised by contacting our support team at support@jackpotconcierge.com and requesting to opt-out.</li>
                            <li><strong>Right to Non-Discrimination:</strong> California residents will not be discriminated against for exercising any of their CCPA rights.</li>
                        </ul>
                        <p>California residents wishing to exercise these rights can submit a verifiable consumer request to us by emailing support@jackpotconcierge.com.</p>
                    </section>
                    <section className="privacy-policy-section">
                        <h3 className="privacy-policy-subtitle">Contact Information</h3>
                        <p>If you have any questions about this Privacy Policy, please contact us at support@jackpotconcierge.com.</p>
                    </section>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PrivacyPolicy;
