import React, { useState, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import vegas from '../vegas.jpg';
import jclogo from '../jclogo.jpeg'; // Adjust the path as necessary
import appStoreBadge from '../Download_on_the_App_Store_Badge_US.svg'; // Import the SVG file
import googlePlayBadge from '../google-play-badge-logo-svgrepo-com.svg'; // Import the Google Play Store badge image

const Home = () => {
    const [logoSize, setLogoSize] = useState(window.innerWidth <= 768 ? '450px' : '600px');
    const [formStyles, setFormStyles] = useState({});

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setLogoSize('450px');
                setFormStyles({
                    padding: '10px',
                    fontSize: '18px'
                });
            } else {
                setLogoSize('600px');
                setFormStyles({});
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{
            backgroundImage: `url(${vegas})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Header />
            <div style={{
                flex: '1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px',
            }}>
                <main style={{
                    padding: formStyles.padding || '20px',
                    textAlign: 'center',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    maxWidth: '800px',
                    width: '85%',
                    fontSize: formStyles.fontSize || '18px',
                    position: 'relative',
                    overflow: 'hidden'
                }}>
                    <div style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: '0.05',
                        zIndex: '0',
                        backgroundImage: `url(${jclogo})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: logoSize,
                        height: logoSize,
                        marginTop: 30
                    }}></div>

                    <div style={{ position: 'relative', zIndex: '1' }}>
                        <h2>Welcome to Jackpot Concierge!</h2>
                        <div style={{
                            justifyContent: 'center',
                            color: '#b42520',
                            display: 'flex',
                            flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                            gap: '10px'
                        }}>
                            <a href="https://apps.apple.com/us/app/jackpot-concierge/id6642683429" target="_blank" rel="noopener noreferrer">
                                <img src={appStoreBadge} alt="Download on the App Store" style={{ height: '60px' }} />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.jackpotconcierge.jackpotconciergeapp" target="_blank" rel="noopener noreferrer">
                                <img src={googlePlayBadge} alt="Get it on Google Play" style={{ height: '60px' }} />
                            </a>
                        </div>
                        <p style={{ fontFamily: 'Montserrat, sans-serif', fontSize: formStyles.fontSize || '24px', fontWeight: 'bold' }}>
                            Whether it’s your first time or you’re a regular, our team is here to ensure your visit to Las Vegas is nothing short of extraordinary.
                        </p>
                        <p style={{ fontFamily: 'Montserrat, sans-serif', fontSize: formStyles.fontSize || '24px' }}>
                            Since Covid-19, prices in Las Vegas have increased, and service and amenities have been reduced. We created <strong>Jackpot Concierge</strong> with one goal in mind - bringing SERVICE back to Las Vegas.
                        </p>

                        <p style={{ fontSize: formStyles.fontSize || '24px' }}>
                            Our luxury concierge team has all of the exclusive insider tips on shows, dining, nightlife, and “off the beaten path” experiences. Additionally, Jackpot clients may receive special discounts or Jackpot perks. Don’t worry about the details; leave your bookings to the professionals.
                        </p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Home;
