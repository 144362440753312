// src/components/Header.js
import React from 'react';
import jclogo from '../jclogo.jpeg'; // Adjust the path as necessary

const Header = () => {
    return (
        <header style={{ 
            backgroundColor: 'black', 
            padding: '10px', 
            textAlign: 'center', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center'
        }}>
            <h1 style={{ 
                fontFamily: "'Great Vibes', cursive", 
                margin: '0 10px', 
                fontSize: '48px',
                fontStyle: 'italic',
                color: 'white'
            }}>Jackpot</h1>
            <h1 style={{ 
                fontFamily: "'Great Vibes', cursive", 
                margin: '0 10px', 
                fontSize: '48px',
                fontStyle: 'italic',
                color: 'white'
            }}>Concierge</h1>
        </header>
    );
};

export default Header;
