// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer style={{ backgroundColor: 'black', padding: '10px 20px', textAlign: 'center' }}>
            <p style={{ color: 'white', margin: 0 }}>
                Jackpot Concierge 2024 &copy; All Rights Reserved.
                {' | '}
                <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>Home</Link>
                {' | '}
                <Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</Link>
                {' | '}
                <Link to="/privacy-policy" style={{ color: 'white', textDecoration: 'none' }}>Privacy Policy</Link>
            </p>
        </footer>
    );
};

export default Footer;
